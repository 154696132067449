<template>
  <div class="dashboard-layout mr-4 mr-md-0">
    <the-header class="mr-n4 mr-md-0" />

    <v-main>
      <v-container class="py-10">
        <v-row>
          <v-col cols="12">
            <h3 class="heading-h3">Blog</h3>
          </v-col>
        </v-row>
        <v-row>
          <!-- Sidebar -->
          <v-col class="" cols="12" lg="3">
            <Sidebar />
          </v-col>

          <!-- Main -->
          <v-col class="mr-n4 mr-md-0" cols="12" lg="9">
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <the-footer class="mr-n4 mr-md-0" />
  </div>
</template>

<script>
import { mapState } from "vuex";

import TheHeader from "@/components/shared/headers/TheHeader";
import Sidebar from "@/components/public/pages/blog/sidebar";
import TheFooter from "@/components/shared/footers/TheFooter";

export default {
  name: "LimeLoansBlogs",
  components: { TheHeader, Sidebar, TheFooter },

  data() {
    return {};
  },

  computed: {
    ...mapState({ auth: "auth" }),
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .b-body-1 {
    //styleName: 14 S/normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
  }
}
</style>
