<template>
  <div class="sidebar">
    <div></div>
    <v-card
      class="transparent d-flex flex-column align-center"
      max-width="266"
      flat
    >
      <v-card class="transparent hidden-md-and-down" width="270" flat tile>
        <div class="list">
          <v-list class="pa-0" width="100%" rounded flat>
            <!-- all news -->
            <v-list-item
              :to="{ name: 'AllNews' }"
              class="gray800--text"
              exact-active-class="bg-active"
            >
              <!-- <v-icon>$walletIcon</v-icon> -->

              <h6 class="h6 ml-1">All news</h6>
            </v-list-item>

            <v-img
              alt="divider"
              class="divider mt-n2"
              src="@/assets/img/dashboard/pages/sidebar/divider.svg"
            />

            <!-- company news -->
            <v-list-item
              :to="{ name: 'CompanyNews' }"
              class="gray800--text"
              exact-active-class="bg-active"
            >
              <!-- <v-icon>$userLIcon</v-icon> -->

              <h6 class="h6 ml-1">Company's news</h6>
            </v-list-item>

            <v-img
              alt="divider"
              class="divider mt-n2"
              src="@/assets/img/dashboard/pages/sidebar/divider.svg"
            />

            <!-- Mfo news -->
            <v-list-item
              :to="{ name: 'MFONews' }"
              class="gray800--text"
              exact-active-class="bg-active"
            >
              <!-- <v-icon>$userLIcon</v-icon> -->

              <h6 class="h6 ml-1">MFSA news</h6>
            </v-list-item>

            <v-img
              alt="divider"
              class="divider mt-n2"
              src="@/assets/img/dashboard/pages/sidebar/divider.svg"
            />

            <!-- Helpful information -->
            <v-list-item
              :to="{ name: 'HelpfulInformation' }"
              class="gray800--text"
              exact-active-class="bg-active"
            >
              <!-- <v-icon>$userLIcon</v-icon> -->

              <h6 class="h6 ml-1">Helpful information</h6>
            </v-list-item>

            <v-img
              alt="divider"
              class="divider mt-n2"
              src="@/assets/img/dashboard/pages/sidebar/divider.svg"
            />

            <!-- financial tips -->
            <v-list-item
              :to="{ name: 'FinancialTips' }"
              class="gray800--text"
              exact-active-class="bg-active"
            >
              <!-- <v-icon>$userLIcon</v-icon> -->

              <h6 class="h6 ml-1">Financial tips</h6>
            </v-list-item>
          </v-list>
        </div>
      </v-card>

      <!-- Need help -->
      <!-- <div class="need-help-btn mt-3 pointer">
          <h6 class="b-btn-text primary--text">Need help</h6>
        </div> -->
    </v-card>

    <div class="list-container hidden-lg-and-up">
      <v-list-item
        :to="{ name: 'AllNews' }"
        class="gray800--text li"
        exact-active-class="bg-active"
      >
        <h6 class="h6 ml-1 h6li">All news</h6>
      </v-list-item>

      <v-list-item
        :to="{ name: 'CompanyNews' }"
        class="gray800--text li"
        exact-active-class="bg-active"
      >
        <h6 class="h6 ml-1 h6li">Company's news</h6>
      </v-list-item>

      <v-list-item
        :to="{ name: 'HelpfulInformation' }"
        class="gray800--text li"
        exact-active-class="bg-active"
      >
        <h6 class="h6 ml-1 h6li">Helpful information</h6>
      </v-list-item>

      <v-list-item
        :to="{ name: 'FinancialTips' }"
        class="gray800--text li"
        exact-active-class="bg-active"
      >
        <h6 class="h6 ml-1 h6li">Financial tips</h6>
      </v-list-item>
    </div>
  </div>
</template>

<script>
export default {
  name: "LimeLoansSidebar",

  data() {
    return {
      dialog: false,
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.h6 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.need-help-btn {
  border: 1px solid #5d9922;
  border-radius: 4px;
  /* Button */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 32px;
  gap: 8px;

  width: 264px;
  height: 48px;
}

.sidebar {
  .list {
    box-shadow: 0px 0px 8px rgba(61, 61, 61, 0.12),
      0px 2px 4px rgba(61, 61, 61, 0.06);
    border-radius: 8px;
  }
}

.list-container {
  display: flex;
  margin: 0;
  width: 90vw;
}
.li {
  width: 10px;
  padding: 0;
}
.h6li {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  padding: 0;
  margin: 0;
}
.bg-active {
  background-color: white;
  color: #5d9922 !important;
  // box-shadow: 0px 0px 8px rgba(61, 61, 61, 0.12),
  //   0px 2px 4px rgba(61, 61, 61, 0.06);
  border-radius: 8px;
}
</style>
